<template>
  <div class="articles-list">
    <Banner>
      <div class="banner-content">
        <MeepIconChat class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.chat") }}</span>
      </div>
    </Banner>
    <PageHeader
      v-model="query"
      :has-actions="isAdmin"
      :has-back="true"
      :has-search="true"
      :tabs="tabs"
      :title="$t('menu.organization-news')"
      @back="goBack"
      @change-tab="changeTab"
    >
      <md-button
        v-if="currentTab === 'tab-all'"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        @click="$router.push('/dashboard/communicate/organization-news/create')"
      >
        <MeepIconActionAdd class="app-icon-stroke-inverted" />
      </md-button>

      <md-button
        v-if="selectedList.length"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        @click="onMultipleTrash"
      >
        <MeepIconActionDelete class="app-icon" />
      </md-button>
      <md-button
        v-if="selectedList.length && isTrashTab"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        @click="onRestore"
      >
        <MeepIconActionRestore class="app-icon" />
      </md-button>
    </PageHeader>
    <div class="articles-list-content page-layout">
      <template v-if="!isLoading">
        <!-- La liste des actualités-->
        <sortable-list
          v-if="filter.length"
          :has-pagination="true"
          :is-card="false"
          :items="filter"
          :parameters="listParameters"
          default-sort="created_at"
          default-sort-order="desc"
          link="/dashboard/communicate/organization-news/view/"
          selectable="multiple"
          @selected="onSelect"
          @item-click="itemClick"
        />
        <NoItem
          v-else
          :buttonText="
            isTrashTab ? '' : $t('organization-news.no-items.buttonText')
          "
          :isCard="true"
          :onClick="onAdd"
          :text="isTrashTab ? '' : $t('organization-news.no-items.text')"
          :title="$t('menu.organization-news')"
        >
          <MeepIconOrganizationNew v-if="!isTrashTab" class="app-icon" />
        </NoItem>
      </template>

      <LoadingCard v-else />

      <!-- Pop-up de confirmation de suppression -->
      <confirm-action-modal
        v-if="isConfirmModalOpen"
        :object-to-act-upon="modalObject"
        :text="confirmModalText"
        @close="isConfirmModalOpen = false"
        @confirm="onConfirmModal"
      />
    </div>
  </div>
</template>

<script>
import organizationsNews from "../../model/organizationsNews.js";
import confirmActionModal from "../../components/modal/confirm-action";
import filesModel from "../../model/files.js";
import Vue from "vue";

/* Fonctions utiles */
import { blobToDataURL, createBlobFromBinary } from "@/services/util";
import { mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import Fuse from "fuse.js";
import LoadingCard from "@/components/LoadingCard";
import Banner from "@/components/Banner";
import MeepIconChat from "@/components/icons/MeepIconChat.vue";
import MeepIconActionDelete from "@/components/icons/MeepIconActionDelete.vue";
import MeepIconActionAdd from "@/components/icons/MeepIconActionAdd.vue";
import MeepIconActionRestore from "@/components/icons/MeepIconActionRestore.vue";
import MeepIconOrganizationNew from "@/components/icons/MeepIconOrganizationNew.vue";
import NoItem from "@/components/NoItem.vue";

export default {
  name: "ListOrganizationNews",

  components: {
    LoadingCard,
    PageHeader,
    Banner,
    MeepIconChat,
    MeepIconActionAdd,
    MeepIconActionDelete,
    MeepIconActionRestore,
    MeepIconOrganizationNew,
    NoItem,
    "confirm-action-modal": confirmActionModal,
  },

  data() {
    return {
      isLoading: false,
      articles: [],
      trashArticles: [],
      organization: {},
      currentTab: "tab-all",
      selectedList: [],

      isConfirmModalOpen: false,

      query: "",

      confirmationContext: "",

      modalObject: {},

      trashListParameters: [
        {
          name: "",
          key: "dataUrl",
          isImage: true,
        },
        {
          name: "Titre",
          key: "title",
        },
        {
          name: "Créée le",
          key: "created_at",
          format: this.$$filters.formatTimestamp,
        },
        {
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
          width: "50px",
        },
        {
          name: "",
          isIcon: true,
          iconFormat: () => "replay",
          iconClass: () => "",
          clickable: true,
          width: "50px",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isClient", "isJEP", "isMEEP"]),

    isTrashTab() {
      return this.currentTab === "tab-trashed";
    },

    listParameters() {
      const defaultParams = [
        {
          name: "",
          key: "dataUrl",
          isImage: true,
          width: "200px",
        },
        {
          name: "Titre",
          key: "title",
          width: "65%",
        },
        {
          name: "Créée le",
          key: "created_at",
          format: this.$$filters.formatTimestamp,
          width: "200px",
        },
      ];

      if (this.canDelete) {
        defaultParams.push({
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
        });
      }

      return defaultParams;
    },

    confirmModalText() {
      switch (this.confirmationContext) {
        case "trash":
          return {
            header: "Suppression",
            body() {
              return "Vous êtes sur le point de supprimer une ou plusieurs actualités.";
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };
        case "trash-multi":
          return {
            header: "Suppression",
            body() {
              return "Vous êtes sur le point de supprimer une ou plusieurs actualités.";
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };
        case "delete-multi":
          return {
            header: "Suppression",
            body() {
              return "Vous êtes sur le point de supprimer definitivement l'actualité multi.";
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };
        case "delete":
          return {
            header: "Suppression",
            body(object) {
              return (
                "Vous êtes sur le point de supprimer definitivement l'actualité <b>" +
                object.title +
                "."
              );
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };
        case "restore":
          return {
            header: "Restaurer",
            body(object) {
              return (
                "Vous êtes sur le point de restaurer l'actualité <b>" +
                object.title +
                "."
              );
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };
        case "restore-multi":
          return {
            header: "Restaurer",
            body() {
              return "Vous êtes sur le point de restaurer l'actualité multi.";
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };
        default:
          return "";
      }
    },

    tabs() {
      if (this.canDelete) {
        return [
          {
            id: "tab-all",
            label: "Tous",
          },
          {
            id: "tab-trashed",
            label: "Corbeille",
          },
        ];
      }

      return [];
    },

    canDelete() {
      return this.isAdmin;
    },

    showAll() {
      return this.currentTab === "tab-all" || !this.currentTab;
    },

    filter() {
      const articles = this.showAll ? this.articles : this.trashArticles;

      if (this.query === "") {
        return articles;
      }

      const fuse = new Fuse(articles, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["title"],
      });

      return fuse.search(this.query).map(item => item.item);
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      const articles = await organizationsNews.getAll();

      this.articles = articles.map(article => {
        this.setNewsDataUrl(article);
        return article;
      });

      if (this.canDelete) {
        const trashArticles = await organizationsNews.getTrashed();

        this.trashArticles = trashArticles.map(article => {
          this.setNewsDataUrl(article);
          return article;
        });
      }

      this.isLoading = false;
    } catch (err) {
      this.articles = [];

      this.$toasted.global.AppError({
        message: err.msg,
      });
      this.isLoading = false;
    }
  },

  methods: {
    changeTab(tabId) {
      this.currentTab = tabId;
    },

    onSelect(items) {
      this.selectedList = items;
    },

    itemClick({ item, parameter }) {
      switch (parameter.iconFormat(item)) {
        case "delete":
          if (this.showAll) {
            this.confirmationContext = "trash";
          } else {
            this.confirmationContext = "delete";
          }
          break;
        case "replay":
          this.confirmationContext = "restore";
          break;
      }

      this.modalObject = item;

      // Open confirmation modal
      this.isConfirmModalOpen = true;
    },

    async onConfirmModal() {
      try {
        this.isLoading = true;

        switch (this.confirmationContext) {
          case "trash":
            await this.trashNews(this.modalObject.id);
            this.$toasted.global.AppSucces({
              message: this.$t("organization-news.trash-success"),
            });
            break;
          case "trash-multi":
            Promise.all(
              this.selectedList.map(async item => await this.trashNews(item.id)),
            );
            this.$toasted.global.AppSucces({
              message: this.$t("organization-news.trash-multi-success"),
            });
            break;

          case "restore":
            await this.restoreNews(this.modalObject.id);
            this.$toasted.global.AppSucces({
              message: this.$t("organization-news.restore-success"),
            });
            break;
          case "restore-multi":
            Promise.all(
              this.selectedList.map(
                async item => await this.restoreNews(item.id),
              ),
            );
            this.$toasted.global.AppSucces({
              message: this.$t("organization-news.restore-multi-success"),
            });
            break;
          case "delete":
            await this.deleteNews(this.modalObject.id);
            this.$toasted.global.AppSucces({
              message: this.$t("organization-news.delete-success"),
            });
            break;
          case "delete-multi":
            Promise.all(
              this.selectedList.map(
                async item => await this.deleteNews(item.id),
              ),
            );
            this.$toasted.global.AppSucces({
              message: this.$t("organization-news.delete-multi-success"),
            });
            break;
        }

        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
        this.isLoading = false;
      }
    },

    async setNewsDataUrl(news) {
      try {
        if (!news.dataUrl && news.logo) {
          const downloadedFile = await filesModel.downloadImage(news.logo);
          const blob = createBlobFromBinary(
            downloadedFile.binary,
            "image/jpeg",
          );
          blobToDataURL(blob, dataUrl => {
            news.dataUrl = dataUrl;
            Vue.set(news, dataUrl, dataUrl);
            return news;
          });
        }
      } catch (err) {
        console.log("Error downloading thumbnail of news", news);
      }
    },

    goBack() {
      window.history.back();
    },

    onMultipleTrash() {
      if (this.currentTab === "tab-all") {
        this.confirmationContext = "trash-multi";
      } else this.confirmationContext = "delete-multi";

      this.isConfirmModalOpen = true;
    },

    onRestore() {
      if (this.selectedList.length > 1) {
        this.confirmationContext = "restore-multi";
      } else this.confirmationContext = "restore";
      this.modalObject = this.selectedList[0];
      this.isConfirmModalOpen = true;
    },

    async trashNews(id) {
      await organizationsNews.putInTrash(id);
      this.articles = this.articles.filter(article => article.id !== id);
      this.trashArticles.push(this.modalObject);
    },

    async restoreNews(id) {
      await organizationsNews.restore(id);
      this.trashArticles = this.trashArticles.filter(
        article => article.id !== id,
      );
      this.articles.push(this.modalObject);
    },

    async deleteNews(id) {
      await organizationsNews.remove(id);
      this.trashArticles = this.trashArticles.filter(
        article => article.id !== id,
      );
    },

    onAdd() {
      this.$router.push("/dashboard/communicate/organization-news/create");
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";

#app {
  .articles-list {
    .page-header--jepa{
      margin-bottom: toRem(15);
      @include for-lg{
        margin-bottom: toRem(30);
      }
    }
    .page-header__title {
      margin-bottom: toRem(20);
      @include for-lg {
        margin-bottom: toRem(35);
      }
    }

    .page-header__tabs-search {
      margin-bottom: 0;
    }

    .page-header__actions {
      .md-ripple {
        padding: 0;
      }
    }

    &-content {
      .no-item {
        .md-button.md-theme-default {
          padding: 0 50px;
        }
      }

      &.page-layout {
        height: 100%;
        @include for-lg {
          margin-top: toRem(30);
        }
      }

      .md-table-cell-selection .md-table-head-label {
        width: 56px;
        @include for-lg {
          width: 83px;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .md-card {
      .md-large {
        min-width: 120px;
        min-height: 90px;
        border-radius: 0;
      }
    }
  }
}
</style>
