var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"articles-list"},[_c('Banner',[_c('div',{staticClass:"banner-content"},[_c('MeepIconChat',{staticClass:"banner-content__icon"}),_c('span',{staticClass:"banner-content__text"},[_vm._v(_vm._s(_vm.$t("menu.chat")))])],1)]),_c('PageHeader',{attrs:{"has-actions":_vm.isAdmin,"has-back":true,"has-search":true,"tabs":_vm.tabs,"title":_vm.$t('menu.organization-news')},on:{"back":_vm.goBack,"change-tab":_vm.changeTab},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[(_vm.currentTab === 'tab-all')?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":function($event){return _vm.$router.push('/dashboard/communicate/organization-news/create')}}},[_c('MeepIconActionAdd',{staticClass:"app-icon-stroke-inverted"})],1):_vm._e(),(_vm.selectedList.length)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":_vm.onMultipleTrash}},[_c('MeepIconActionDelete',{staticClass:"app-icon"})],1):_vm._e(),(_vm.selectedList.length && _vm.isTrashTab)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":_vm.onRestore}},[_c('MeepIconActionRestore',{staticClass:"app-icon"})],1):_vm._e()],1),_c('div',{staticClass:"articles-list-content page-layout"},[(!_vm.isLoading)?[(_vm.filter.length)?_c('sortable-list',{attrs:{"has-pagination":true,"is-card":false,"items":_vm.filter,"parameters":_vm.listParameters,"default-sort":"created_at","default-sort-order":"desc","link":"/dashboard/communicate/organization-news/view/","selectable":"multiple"},on:{"selected":_vm.onSelect,"item-click":_vm.itemClick}}):_c('NoItem',{attrs:{"buttonText":_vm.isTrashTab ? '' : _vm.$t('organization-news.no-items.buttonText'),"isCard":true,"onClick":_vm.onAdd,"text":_vm.isTrashTab ? '' : _vm.$t('organization-news.no-items.text'),"title":_vm.$t('menu.organization-news')}},[(!_vm.isTrashTab)?_c('MeepIconOrganizationNew',{staticClass:"app-icon"}):_vm._e()],1)]:_c('LoadingCard'),(_vm.isConfirmModalOpen)?_c('confirm-action-modal',{attrs:{"object-to-act-upon":_vm.modalObject,"text":_vm.confirmModalText},on:{"close":function($event){_vm.isConfirmModalOpen = false},"confirm":_vm.onConfirmModal}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }